import {mapActions, mapGetters, mapMutations} from "vuex";

//sections
export default {
    name: "login-first-step",
    components: {},
    props: {
        errorPhone: {
            type: Boolean,
            default: false
        },
        errorCode: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            selectedService: [],
            openContacts: true,
            openDelivery: false,
            disabledCode: true,
            disabledPhone: false,
            closeSelect: false,
            phone: '',
            code: '',
        }
    },
    created() {
    },
    mounted() {
    },
    watch: {
        phone() {
            this.smsLogin({
                phone: this.phone.replace(/[^\d]/g, ''),
                code: this.code
            })
            if (this.phone.length === 19) {
                this.sendPhone({phone: this.phone.replace(/[^\d]/g, '')}).then(() => {
                    if (this.responseCode.message) {
                        this.$toasted.success(this.responseCode.message);
                        this.$refs.enterCode.focus()
                        this.disabledCode = false
                    }
                }).catch((error) => {
                    console.log()
                    this.$toasted.error(error.message);
                })
            } else {
                this.disabledCode = true
            }
            this.$refs.smsPhone.textContent = ''
        },
        code() {

            if (this.code.length === 6) {
                this.disabledCode = false
                this.disabledPhone = true
                this.sendConfirmCode()
            }
        }
    },
    computed: {
        passedStage() {
            return this.code.length === 6 && this.phone.length === 19
        },
        ...mapGetters({
            isAuth: 'auth/isAuthenticated',
            sendingCode: 'auth/setRegisterCodeLoading',
            responseCode: 'auth/setRegisterCode',
            sendingCheckCode: 'auth/setRegisterCheckCodeLoading',
            setRegisterCheckCode: 'auth/setRegisterCheckCode'
        }),
    },
    methods: {
        ...mapMutations({
            smsLogin: 'order/SET_LOGIN_SMS_CODE',
            closeThanksPopup: 'setting/SHOW_THANKS_POPUP',
            closeRegisterPopup: 'auth/SHOW_REGISTER_POPUP',
            setToken: 'auth/SET_TOKEN'
        }),
        ...mapActions({
            sendPhone: 'auth/REGISTER_SEND_CODE',
            checkCode: 'auth/REGISTER_CHECK_CODE'
        }),

        openContactSelect() {
            this.openContacts = !this.openContacts
        },
        sendConfirmCode() {
            this.checkCode({
                phone: this.phone.replace(/[^\d]/g, ''),
                code: this.code
            }).then(() => {
                if (this.setRegisterCheckCode.userWasCreated) {
                    this.$toasted.success(this.$t('auth.success.register'));
                } else {
                    this.$toasted.success(this.$t('auth.success.login'));
                }
                
                this.setToken(this.setRegisterCheckCode)
            }).catch(() => {
                this.$toasted.error(this.$t('form.sendError'));
            })
        },
        confirmOrder() {
        },
    }
}
